import { checkboxClasses } from "@mui/material/Checkbox";

// color design tokens export "#FC0404" ,//
export const tokensDark = {
  grey: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414", //
    1000: "#000000", // manually adjusted
  },
  primary: {
    50: "#004040",
    100: "#004040",
    200: "#004040",
    300: "#004c4c",
    400: "#005959",
    500: "#006666",
    600: "#007373", // manually adjusted
    700: "#008080",
    800: "#198c8c",
    900: "#329999",
  },
  secondary: {
    50: "#f0f0f0", // manually adjusted
    100: "#fff6e0",
    200: "#ffedc2",
    300: "#008080",
    400: "#ffda85",
    500: "#ffd166",
    600: "#cca752",
    700: "#997d3d",
    800: "#665429",
    900: "#332a14",
  },
};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);
// a50000
export const machineStatusColor = {
  OPERATIONAL: "green",
  UNDER_OBSERVATION: "#cca752",
  BREAKDOWN: "#b71c1c",
};
// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.grey[900],
              light: tokensDark.primary[700],
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.secondary[600],
              light: tokensDark.secondary[700],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[700],
            },
            background: {
              default: tokensDark.grey[600],
              alt: tokensDark.primary[500],
              // paper: "#242424",
            },
            appColors: {
              navBarBackground: tokensDark.primary[0],
              sideBarText: tokensDark.grey[0],
              sideBarAppLogo: "#fff",
            },
            ...machineStatusColor,
          }
        : {
            // palette values for light mode
            primary: {
              ...tokensLight.primary,
              main: tokensDark.primary[700],
              light: tokensDark.primary[100],
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.secondary[300],
              light: tokensDark.secondary[700],
            },
            neutral: {
              ...tokensLight.grey,
              main: tokensDark.grey[0],
            },
            background: {
              default: tokensDark.grey[10],
              alt: tokensDark.grey[50],
            },
            appColors: {
              navBarBackground: tokensDark.primary[700],
              sideBarText: tokensDark.grey[0],
              sideBarAppLogo: "#fff",
            },
            ...machineStatusColor,
          }),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // backgroundColor: tokensDark.primary[700],
            "&:hover": {
              backgroundColor: tokensDark.primary[700],
              color: "#fff",
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: "#3c52b2",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            // Customize the icon color here
            // color: mode === "dark" ? tokensDark.secondary[600] : tokensDark.primary[700], // Use 'fill' instead of 'color'
            // Add any other icon style customizations here
            // color: "white",
            //backgroundColor: mode === "dark" ? "#fff" : "#000",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // Customize the icon color here
            color: mode === "dark" ? "#fff" : "#000",
            backgroundColor: mode === "dark" ? "#333333" : "#ffff", // Use 'fill' instead of 'color'
            // Add any other icon style customizations here
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            // Customize the icon color here
            //color: "black",
            color: mode === "dark" ? "#ffff" : "#000",

            // backgroundColor: "white", // Use 'fill' instead of 'color'
            // Add any other icon style customizations here
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#fff" : tokensDark.primary[50],
            [`&.${checkboxClasses.checked}`]: {
              color: mode === "dark" ? "#fff" : tokensDark.primary[500],
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#ffff" : "#000",
            "&.Mui-focused": {
              color: mode === "dark" ? "#fff" : "#000",
            },
          },
        },
      },
    },
  };
};
